import Ajv2020 from 'ajv/dist/2020' // draft that support "unevaluatedProperties"
import addFormats from 'ajv-formats' // format validation come from a separate packages (email, date, etc)
import jeMetaSchema from "./meta-schema"
import ajvMetaSchema from "ajv/dist/refs/json-schema-2020-12/schema.json"

class AjvValidator {
  constructor() {
    this.ajv = new Ajv2020({
      strict: false, // to ignore non json schema keywords (options, defaultProperties, etc)
      allErrors: true // otherwise it shows just the first error per keyword
    })

    this.ajv.addFormat("tabs", {}) // suppress warning for unknown formats
    this.ajv.addFormat("table", {}) // suppress warning for unknown formats
    addFormats(this.ajv) // activates formats validation (email, date, etc)
  }

  validate202012 (json) {
    return this.validate(ajvMetaSchema, json)
  }

  validateJeMetaSchema (json) {
    return this.validate(jeMetaSchema, json)
  }

  validate (schema, json) {
    let errors = []

    try {
      const validate = this.ajv.compile(schema)
      validate(json)

      if (Array.isArray(validate.errors)) {
        errors = validate.errors
      }
    } catch (error) {
      console.log('caught error:', error)
    }

    return errors
  }
}

export default AjvValidator